import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: 'index',
      meta: {
        title: '汽车消费券',
        keepAlive: true
      },
      component: () => import("../components/Index.vue"),
    },
    {
      path: "/newIndex",
      name: 'newIndex',
      meta: {
        title: '汽车消费券',
        keepAlive: true
      },
      component: () => import("../components/NewIndex.vue"),
    },
    {
      path: "/dxIndex",
      name: 'dxIndex',
      meta: {
        title: '汽车消费券',
        keepAlive: true
      },
      component: () => import("../components/DxIndex.vue"),
    },
    {
      path: "/detail",
      name: 'detail',
      meta: {
        title: '详情',
      },
      component: () => import("../components/Detail.vue"),
    },
    {
      path: "/orderDetail",
      name: 'orderDetail',
      meta: {
        title: '订单详情',
      },
      component: () => import("../components/OrderDetail.vue"),
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title
  }

  if (to.meta.requireAuth) {
    let isLogin = localStorage.getItem('isLogin') == 'true';
    if (isLogin) {
      next()
    } else {
      next({
        path: "/login",
        query: { redirect: to.path }//将跳转的路由path作为参数，登陆成功后跳转到该路由
      })
    }
  } else {
    next();
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
