import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    },
    mutations: {
        setToken(state, val) {
            state.token = val
            localStorage.setItem('token', val);
        },
        loginOut(state) {
            state.token = ''
            localStorage.removeItem('token')
        },

    }
})
